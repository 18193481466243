import { Module } from 'vuex';

import type { Node } from '@/components/Schematic/iso/Node';
import Configuration from '@/helpers/Configuration';
import $router from '@/router';

export type NodesStoreState = {
    entriesById: { [id: Uuid]: Node };
    entriesByPos: { [x: number]: { [y: number]: Node } };
    lastUpdatedEntry: any;
};

export default <Module<NodesStoreState, {}>>{
    namespaced: true,
    state: {
        entriesById: {},
        entriesByPos: {},
        lastUpdatedEntry: null,
    },
    getters: {
        /**
         * Gets a node by its UUID, returns null when there is no Node with such an ID.
         */
        entryById(state: NodesStoreState): (id: Uuid) => Node | null {
            return (id: Uuid) => state.entriesById[id];
        },
        /**
         * Gets a node based on its coordinates, returns null when there is no Node located at the
         * given coordinates.
         */
        entryByPos(state): (c: Coordinates) => Node | null {
            return ({ x, y }) => state.entriesByPos[x]?.[y] || null;
        },
        /**
         * Gets a list of all Nodes in the store.
         */
        entries(state): () => Node[] {
            return () => Object.values(state.entriesById);
        },
        /**
         * Gets a list of all Nodes in the store that have a label.
         */
        entriesWithLabels(state): Node[] {
            return Object.values(state.entriesById).filter(
                (n: Node) => n.labelText
            );
        },
    },
    actions: {
        updateLabelText({ getters, dispatch }, { id, text, doNotStore }) {
            (getters.entryById(id) as Node).labelText = text.trim();
            if (!doNotStore) {
                dispatch('history/add', {}, { root: true });
            }
        },
        updateLabelHeight(
            { getters, dispatch },
            { id, labelHeight, doNotStore }
        ) {
            getters.entryById(id).labelHeight = labelHeight;
            if (!doNotStore) {
                dispatch('history/add', {}, { root: true });
            }
        },
        updateProductNodeById(
            { getters, state, dispatch },
            { id, nodeId, doNotStore }
        ) {
            const node = getters.entryById(id);
            state.lastUpdatedEntry = node;
            node.setProductNode(nodeId);
            if (!doNotStore) {
                dispatch('history/add', {}, { root: true });
            }
        },
        updateProductById(
            { getters, state, dispatch },
            { id, productId, doNotStore }
        ) {
            const node = getters.entryById(id);
            state.lastUpdatedEntry = node;
            node.setProduct(productId);
            if (!doNotStore) {
                dispatch('history/add', {}, { root: true });
            }
        },
        updateProductSkuById(
            { getters, state, dispatch },
            { id, skuId, doNotStore }
        ) {
            const node = getters.entryById(id);
            state.lastUpdatedEntry = node;
            node.setProductSku(skuId);
            if (!doNotStore) {
                dispatch('history/add', {}, { root: true });
            }
        },
        reset({ getters, dispatch }, { doNotStore }) {
            getters.entries().forEach((node: Node) =>
                dispatch('removeById', {
                    id: node.id,
                    doNotStore: true,
                })
            );
            if (!doNotStore) {
                dispatch('history/add', {}, { root: true });
            }
        },
        add({ state, dispatch }, { entry, doNotStore }) {
            state.entriesById[entry.id] = entry;
            const x = Math.round(entry.position.x / Configuration.TILE_SIZE);
            const y = Math.round(entry.position.y / Configuration.TILE_SIZE);
            if (!state.entriesByPos[x]) {
                state.entriesByPos[x] = {};
            }
            state.entriesByPos[x][y] = entry;
            if (!doNotStore) {
                dispatch('history/add', {}, { root: true });
            }
        },
        moveEntryByPos({ state, dispatch }, { from, to }) {
            const entry = state.entriesByPos[from.x][from.y];
            if (!state.entriesByPos[to.x]) {
                state.entriesByPos[to.x] = {};
            }
            state.entriesByPos[to.x][to.y] = entry;
            delete state.entriesByPos[from.x][from.y];

            // Remove col when empty
            if (!Object.keys(state.entriesByPos[from.x]).length) {
                delete state.entriesByPos[from.x];
            }
        },
        removeById({ state, getters, dispatch }, { id, doNotStore }) {
            const entry = getters.entryById(id);
            if (!entry) {
                throw new Error(
                    'Could note remove entry by id. No entry found with id: ' +
                        id
                );
            }
            const x = Math.round(entry.position.x / Configuration.TILE_SIZE);
            delete state.entriesByPos[x][
                Math.round(entry.position.y / Configuration.TILE_SIZE)
            ];
            delete state.entriesById[id];

            // Remove col when empty
            if (!Object.keys(state.entriesByPos[x]).length) {
                delete state.entriesByPos[x];
            }

            // Remove connections connected to removed node
            dispatch('connections/removeByNodeId', { id }, { root: true });

            //Remove css vars
            document.documentElement.style.removeProperty(
                `--node-${id}-middle-x`
            );
            document.documentElement.style.removeProperty(
                `--node-${id}-middle-y`
            );
            document.documentElement.style.removeProperty(
                `--node-${id}-label-height`
            );

            // Redraw all connections as it may interrupted some
            if (!doNotStore) {
                dispatch(
                    'connections/redrawAll',
                    { filterNodeId: null },
                    { root: true }
                );
            }

            // Close settings panel of this node if it is opened
            if (
                $router.currentRoute.value.name === 'Settings' &&
                $router.currentRoute.value.params.nodeId === id
            ) {
                $router.push({ name: 'Schematic' });
            }
            if (!doNotStore) {
                dispatch('history/add', {}, { root: true });
            }
        },
    },
};
