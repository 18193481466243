import '@/scss/bootstrap_preloader.scss';
import 'bootstrap';
import 'bootstrap-icons/font/bootstrap-icons.css';

import { createApp } from 'vue';
import WebFont from 'webfontloader';

import App from './App.vue';
import globalDirectives from './directives';
import router from './router';
import store from './store';

window.fontsLoaded = new Promise<void>((resolve, reject) => {
    WebFont.load({
        custom: {
            families: ['Museo Sans:100,300,500,700:latin-ext'],
            urls: [
                require('@/assets/fonts/MuseoSans_100.otf'),
                require('@/assets/fonts/MuseoSans_300.otf'),
                require('@/assets/fonts/MuseoSans_500.otf'),
                require('@/assets/fonts/MuseoSans_700.otf'),
            ],
        },
        active() {
            resolve();
        },
    });
});

const app = createApp(App);

app.config.errorHandler = (err, instance, info) => {
    if (err) {
        console.error('Unhandled error:');
        console.error(err);
    }
};

app.use(router).use(store).use(globalDirectives).mount('#app');
