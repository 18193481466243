import * as PIXI from 'pixi.js';

import { ISO_ANGLE, ISO_ROTATION } from '@/consts';
import { insertNewlines } from '@/helpers/Node/InsertNewline';

export class Label extends PIXI.Container {
    /**
     * Text displayed inside of label, can contain multiple lines.
     */
    private labelText = '';

    /**
     * Untransformed label text, differs from `labelText` because `labelText` may have several
     * newlines inserted in it to ensure the label does not become too wide.
     */
    private originalText = '';

    /**
     * Background rounded rectangle behind label.
     */
    private backgroundGraphic!: PIXI.Graphics;

    /**
     * Text object
     */
    private textGraphic?: PIXI.Text;

    /**
     * Have the web fonts been loaded yet? We cannot create a Text object before this.
     */
    private fontsLoaded = false;

    /**
     * Y position of the label relative to it's parent.
     */
    private labelHeight = 0;

    private boxWidth = 0;
    private boxHeight = 31;

    /**
     * Padding/margin between text and borders of the background box.
     */
    private readonly padding = 8;

    /**
     * Font family of label text.
     */
    public readonly fontFamily = 'Museo Sans';

    /**
     * Label font style.
     */
    public readonly fontStyle = new PIXI.TextStyle({
        fontFamily: this.fontFamily,
        fontSize: 10,
        fill: 0x11263b,
        align: 'center',
    });

    /**
     * Y-axis distance between label and origin.
     */
    public get height() {
        return this.labelHeight;
    }
    public set height(h: number) {
        this.labelHeight = h;
        this.updateGraphics();
    }

    /**
     * Label text.
     */
    public get text() {
        return this.originalText;
    }
    public set text(t: string) {
        this.originalText = t;
        t = t.trim();

        // When text gets too wide it can no longer be rendered to a texture so lines that are
        // longer than ~520 characters needs to be split up into multiple lines.
        this.labelText = t
            .split(/[\r\n]+/g)
            .map((line) => insertNewlines(line.trim(), 50))
            .join('\n');

        this.updateGraphics();
    }

    constructor() {
        super();
        window.fontsLoaded.then(() => {
            this.fontsLoaded = true;
            this.setupGraphics();
            this.updateGraphics();
        });
        this.scale.y = Math.tan(ISO_ANGLE * 2 * (Math.PI / 180));
        this.rotation = -ISO_ROTATION;
    }

    private setupGraphics() {
        this.backgroundGraphic = new PIXI.Graphics();

        this.textGraphic = new PIXI.Text('', this.fontStyle);
        this.textGraphic.anchor.set(0.5);
        this.textGraphic.resolution = 5.5;
        this.addChild(this.backgroundGraphic, this.textGraphic);
    }

    /**
     * Clears and redraws the graphic elements of the label, this function is called automatically
     * for most public getters and setters.
     */
    public updateGraphics() {
        if (this.labelText.length === 0) {
            if (this.textGraphic) {
                this.textGraphic.text = '';
            }
            this.backgroundGraphic?.clear();
        } else if (this.fontsLoaded) {
            const textMetrics = PIXI.TextMetrics.measureText(
                this.labelText,
                this.fontStyle
            );

            this.boxWidth = textMetrics.width + this.padding * 2;
            this.boxHeight = textMetrics.height + this.padding * 2;

            this.backgroundGraphic.clear();
            this.backgroundGraphic.beginFill(0xffffff);
            this.backgroundGraphic.lineStyle(2, 0x0, 0.3);
            this.backgroundGraphic.drawRoundedRect(
                this.boxWidth / -2,
                -this.labelHeight - this.boxHeight / 2 + 4.5,
                this.boxWidth,
                this.boxHeight,
                5
            );
            this.backgroundGraphic.endFill();

            this.textGraphic!.text = this.labelText;
            this.textGraphic!.position.x = 0;
            this.textGraphic!.position.y = -this.labelHeight + 4;
        }
    }

    public getGlobalTextPosition(): null | {
        top: number;
        bottom: number;
        left: number;
        right: number;
    } {
        if (!this.textGraphic) {
            return null;
        }
        const { x, y } = this.textGraphic.getGlobalPosition();
        const { x: width, y: height } = this.distanceToGlobal({
            x: this.boxWidth,
            y: this.boxHeight,
        });
        const out = {
            top: y + height / 2,
            bottom: y - height / 2,
            left: x + width / 2,
            right: x - width / 2,
        };
        console.log(out);
        return out;
    }

    private distanceToGlobal(c: Coordinates): Coordinates {
        const { x: x1, y: y1 } = this.textGraphic!.toGlobal(c);
        const { x: x2, y: y2 } = this.textGraphic!.toGlobal({ x: 0, y: 0 });
        return { x: x2 - x1, y: y2 - y1 };
    }
}
