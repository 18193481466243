/**
 * Takes the given string and breaks up any lines that are longer than the provided maximum length.
 * The algorithm tries to find the nearest whitespace so words are not cut in two, but if no break
 * can be found it will just insert a newline at the last possible position.
 *
 * Note: This function will never insert more then 1000 newlines.
 *
 * ## Example:
 * insertNewlines("AAABBBCCCDDD", 5) === "AAABB\nBCCCD\nDD"
 * insertNewlines("AAA BBB CCC DDD", 5) === "AAA\nBBB\nCCC\nDDD"
 *
 * @param {number} maxLength - Maximum amount of characters a line is allowed to be before a line
 *                             break must be inserted.
 */
export function insertNewlines(input: string, maxLength: number = 512): string {
    let output = '';
    const MAX_ROUNDS = 1000;
    let i = 0;
    for (i = 0; i <= MAX_ROUNDS && input.length > maxLength; i++) {
        const nearestSpace = input.slice(0, maxLength).lastIndexOf(' ');
        if (nearestSpace > 0) {
            output += input.slice(0, nearestSpace).trim() + '\n';
            input = input.slice(nearestSpace).trim();
        } else {
            output += input.slice(0, maxLength) + '\n';
            input = input.slice(maxLength);
        }
    }
    if (i >= MAX_ROUNDS) {
        console.error('insertNewlines reached max rounds!!');
    }
    output += input;
    return output;
}
